import {
    tableState,
    tableMutations,
    tableActions,
    tableGetters
} from "@tt/vue-components";

import orderNoFilter from "@/store/modules/giftcardsOverview/filter/orderNoFilter";
import emailFilter from "@/store/modules/giftcardsOverview/filter/emailFilter";
import typeFilter from "@/store/modules/giftcardsOverview/filter/typeFilter";
import statusFilter from "@/store/modules/giftcardsOverview/filter/statusFilter";

import orderService from "@/services/OrderService";

const giftcardsOverview = {
    requestController: null,
    namespaced: true,
    state: {
        ...tableState,
        item: null,
        sorting: { sortBy: ["order.orderDt"], sortDesc: [true] },
        pagination: { itemsPerPage: 25, itemsPage: 1 },
        filters: [],
        defaultFilters: []
    },
    modules: {
        orderNoFilter,
        emailFilter,
        typeFilter,
        statusFilter
    },
    mutations: {
        ...tableMutations,
        SET_ITEM(state, item) {
            state.item = item;
        }
    },
    actions: {
        ...tableActions,
        fetchItems(context, refresh = false) {
            if (refresh || context.state.items.length === 0) {
                context.commit("SET_LOADING", true);

                // Little nasty hack to prevent wrong results
                let typeFilterData = context.getters['typeFilter/data'];
                if (typeFilterData?.length === 0) {
                    const search = [
                        ...context.state.search,
                        ...[
                            {column: "orderItemType.type[]", value: "giftcard_digital"},
                            {column: "orderItemType.type[]", value: "giftcard_physical"}
                        ],

                    ]
                    context.commit('SET_SEARCH', search)
                }

                const params = { pagination: context.state.pagination, sorting: context.state.sorting, search: context.state.search };

                orderService.orderItems.list(params)
                    .then(json => {
                        if (json) {
                            context.commit("SET_ITEMS", json);
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    })
                    .finally(() => {
                        context.commit("SET_LOADING", false);
                    });
            }
        },
        search(context) {
            const params = [];
            const defaultParams = [];

            if (context.state.defaultFilters.length || context.state.filters.length) {
                context.state.defaultFilters.forEach(filter => {
                    let filterParams = context.getters[filter.name + "/toParams"];
                    if (filterParams !== undefined && filterParams.length > 0) {
                        for (let i = 0; i < filterParams.length; i++) {
                            defaultParams.push(JSON.parse(JSON.stringify(filterParams[i])));
                        }
                    }
                });
                context.state.filters.forEach(filter => {
                    let filterParams = context.getters[filter.name + "/toParams"];
                    if (filterParams !== undefined && filterParams.length > 0) {
                        for (let i = 0; i < filterParams.length; i++) {
                            params.push(JSON.parse(JSON.stringify(filterParams[i])));
                        }
                    }
                });
            }
            const allParams = [...defaultParams, ...params]
            if (allParams.length) {
                context.commit("SET_SEARCH", allParams.filter(param => {
                    return param.value !== undefined && param.value !== null && param.value !== "" && param.value !== []
                }));
            }
            context.dispatch("fetchItems", true);
        },
        fetchOrder({commit, state})
        {
            if (!state.item.order['@id']) {
                return;
            }
            orderService.orders.getResource(state.item.order['@id'])
                .then(json => {
                    if (json) {
                        commit("ordersOverview/order/SET_ITEM", json, {root: true});
                    }
                })
                .catch(err => {
                    console.log(err);
                })
                .finally(() => {
                    commit("SET_LOADING", false);
                });
        },
        selectItem({commit, dispatch}, item) {
            commit("SET_ITEM", item);
            if (item) {
                dispatch('fetchOrder')
            }
        },
    },
    getters: {
        ...tableGetters
    }
};

export default giftcardsOverview;

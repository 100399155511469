const typeFilter = {
    namespaced: true,
    state: {
        data: 'new'
    },
    mutations: {
        SET_DATA(state, orderNo) {
            state.data = orderNo;
        }
    },
    actions: {
        setData(context, value) {
            context.commit("SET_DATA", value);
        }
    },
    getters: {
        data: state => {
            return state.data;
        },
        toParams: state => {
            switch (state.data) {
                case 'shipped':
                    return [{ column: 'qtyShipped[gte]', value: 1 }];
                case 'cancelled':
                    return [{ column: 'qtyCancelled[gte]', value: 1 }];
                case 'new':
                default:
                    return [{ column: 'qtyCancelled[lt]', value: 1 }, { column: 'qtyShipped[lt]', value: 1 }];
            }
        }
    }
};

export default typeFilter;

const typeFilter = {
    namespaced: true,
    state: {
        data: [
           'giftcard_digital',
           'giftcard_physical'
        ]
    },
    mutations: {
        SET_DATA(state, orderNo) {
            state.data = orderNo;
        }
    },
    actions: {
        setData(context, value) {
            context.commit("SET_DATA", value);
        }
    },
    getters: {
        data: state => {
            return state.data;
        },
        toParams: state => {
            let searchParams = [];
            state.data.forEach((item) => {
                // Filter bundle magic: https://github.com/metaclass-nl/filter-bundle
                searchParams.push({ column: "orderItemType.type[]", value: item });
            });
            return searchParams
        }
    }
};

export default typeFilter;

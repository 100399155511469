import Vue from "vue";
import Vuex from "vuex";

import flags from "@/store/modules/flags";
import ordersOverview from "@/store/modules/ordersOverview";
import giftcardsOverview from "@/store/modules/giftcardsOverview";
import orderImportsOverview from "@/store/modules/orderImportsOverview";
import pickupOrdersOverview from "@/store/modules/pickupOrdersOverview";
import order from "@/store/modules/ordersOverview/order";
import deliveryMethodsOverview from "@/store/modules/deliveryMethodsOverview";
import locationsList from "@/store/modules/locationsList";
import entityAssetsList from "@/store/modules/entityAssetsList";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production'

const store = new Vuex.Store({
  modules: {
    flags,
    ordersOverview,
    giftcardsOverview,
    orderImportsOverview,
    pickupOrdersOverview,
    orderOverview: order,
    deliveryMethodsOverview,
    locationsList,
    entityAssetsList
  },
  strict: process.env.NODE_ENV !== "production",
  plugins: debug ? [Vuex.createLogger()] : []
});

export default store;
